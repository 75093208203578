<template>
  <div
    :class="{
      'card mt-2': fieldPage.selectedFieldTypeOrgUnit,
      'mt-1': !fieldPage.selectedFieldTypeOrgUnit,
    }"
  >
    <div
      :class="{
        'card-body': fieldPage.selectedFieldTypeOrgUnit,
      }"
    >
      <template v-if="fieldPage.selectedFieldTypeOrgUnit">
        <h3 class="card-title mb-0">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "Fields.CustomSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="col-12 mt-3" v-if="!isRecordEdit">
          <div class="alert alert-info text-dark mb-0">
            <i class="bi bi-info-circle"></i>
            {{
              $t(
                "BaseModelFields.SelectlistWarning",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </div>
      </template>
      <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
        <li
          v-for="(item, i) in tabList"
          :key="i"
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            :class="{
              active:
                (!isRecordEdit && item.value == 'User') ||
                (isRecordEdit && item.value == tabData.activeTabType),
            }"
            @click="tabClick(item)"
            :id="`pills-${item.value.toLowerCase()}-tab`"
            data-bs-toggle="pill"
            :data-bs-target="`#pills-${item.value.toLowerCase()}`"
            type="button"
            role="tab"
            :aria-selected="
              (!isRecordEdit && item.value == 'User') ||
              (isRecordEdit && item.value == tabData.activeTabType)
            "
          >
            <i :class="item.iconClass"></i>
            {{
              $t(
                "Fields." + item.value,
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
      </ul>
      <div class="tab-content">
        <div
          v-for="(item, i) in tabList"
          :key="i"
          :class="{
            'active show': item.value == tabData.activeTabType,
          }"
          class="tab-pane fade"
          :id="`#pills-${item.value.toLowerCase()}`"
          role="tabpanel"
        >
          <template v-if="item.value == tabData.activeTabType">
            <div
              v-for="(radioItem, radioIndex) in item.radioButtons"
              :key="radioIndex"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="radio"
                :name="`${item.prefix}organizationalUnit`"
                :checked="
                  isRecordEdit
                    ? checked(radioIndex, radioItem)
                    : radioItem.active
                "
                @change="filter(radioItem, item)"
                :id="radioItem.id"
              />
              <label class="form-check-label" :for="radioItem.id">
                {{
                  $t(
                    "Fields." + radioItem.name,
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <div
                class="child-div mt-2 mb-2"
                v-if="
                  item.value == 'User' && radioItem.param == parentRadioType
                "
              >
                <div
                  v-for="(
                    childRadio, childRadioIndex
                  ) in radioItem.childRadioButtons"
                  :key="childRadioIndex"
                  class="form-check"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    @change="onChildFilter(childRadio.param, childRadio.depth)"
                    :name="`${item.prefix}ChildOrAllGroups`"
                    :checked="childRadio.param == childRadioType"
                    :id="childRadio.id"
                  />
                  <label class="form-check-label" :for="childRadio.id">
                    {{
                      $t(
                        "Fields." + childRadio.name,
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <div
                    class="child-div mt-2 mb-2"
                    v-if="
                      childRadio.childItemRadioButtons != null &&
                      childRadio.param == childRadioType
                    "
                  >
                    <div
                      v-for="(
                        childRadioItem, childRadioItemIndex
                      ) in childRadio.childItemRadioButtons"
                      :key="childRadioItemIndex"
                      class="form-check form-check-inline"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        :name="`${item.prefix}childGroupFilter`"
                        :id="childRadioItem.id"
                        :checked="childRadioItem.param == childItemRadioType"
                        @change="onChildFilterFrom(childRadioItem.name)"
                      />
                      <label class="form-check-label" :for="childRadioItem.id">
                        {{
                          $t(
                            "Fields." + childRadioItem.name,
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </label>
                    </div>
                    <div
                      v-for="(
                        childRadioItem, childRadioItemIndex
                      ) in childRadio.childItemRadioButtons"
                      :key="childRadioItemIndex"
                    >
                      <div
                        class="child-div mt-2 mb-2"
                        v-if="
                          childRadioItem.name == childItemRadioType &&
                          childRadioItem.name == 'FilterFromField'
                        "
                      >
                        <FormSelect
                          type="SelectWithRemoteDataAndInternalSearch"
                          @onChange="onChangeControlling"
                          :isParameters="true"
                          :isGatewayRequest="true"
                          :selectedData="selectedFilterFromFieldValues"
                          :requestUrl="
                            String.format(
                              '/Lcdp-OrganizationalUnitControlingItems?customObjectPublicId={0}&organizationalUnits={1}',
                              this.$route.params.customObjectId,
                              this.tabData.organizationalUnitFilterType
                            )
                          "
                        />
                      </div>
                      <div
                        class="child-div mt-2 mb-2"
                        v-if="
                          childRadioItem.name == childItemRadioType &&
                          childRadioItem.name == 'FilterFromGiven'
                        "
                      >
                        <FormSelect
                          type="SelectWithRemoteDataAndInternalSearch"
                          @onMultipleSelectChange="onChangeGroups"
                          :isParameters="true"
                          :isMultiple="true"
                          :isGatewayRequest="true"
                          :selectedData="selectedFilterFromGivenValues"
                          :requestUrl="
                            String.format(
                              '/Lcdp-GetOrganizationalUnitItemsForField?name={0}&filterType={1}&isAddCurrentKeys=false',
                              this.tabData.organizationalUnitFilterType,
                              this.tabData.organizationalUnitFilterType
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="child-div mt-2 mb-2"
                v-if="
                  (item.value == 'UserGroup' ||
                    item.value == 'PermissionGroup') &&
                  radioItem.param == parentRadioType
                "
              >
                <div
                  v-for="(
                    childRadioItem, childRadioItemIndex
                  ) in radioItem.childRadioButtons"
                  :key="childRadioItemIndex"
                  class="form-check form-check-inline"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="`${item.prefix}childGroupFilter`"
                    :id="childRadioItem.id"
                    :checked="childRadioItem.param == childItemRadioType"
                    @change="onChildFilterFrom(childRadioItem.name)"
                  />
                  <label class="form-check-label" :for="childRadioItem.id">
                    {{
                      $t(
                        "Fields." + childRadioItem.name,
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                </div>
                <div
                  v-for="(
                    childRadioItem, childRadioItemIndex
                  ) in radioItem.childRadioButtons"
                  :key="childRadioItemIndex"
                >
                  <div
                    class="child-div mt-2 mb-2"
                    v-if="
                      childRadioItem.name == childItemRadioType &&
                      childRadioItem.name == 'FilterFromField'
                    "
                  >
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      @onChange="onChangeControlling"
                      :isParameters="true"
                      :isGatewayRequest="true"
                      :selectedData="selectedFilterFromFieldValues"
                      :requestUrl="
                        String.format(
                          '/Lcdp-OrganizationalUnitControlingItems?customObjectPublicId={0}&organizationalUnits={1}',
                          this.$route.params.customObjectId,
                          this.tabData.organizationalUnitFilterType
                        )
                      "
                    />
                  </div>
                  <div
                    class="child-div mt-2 mb-2"
                    v-if="
                      childRadioItem.name == childItemRadioType &&
                      childRadioItem.name == 'FilterFromGiven'
                    "
                  >
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      @onMultipleSelectChange="onChangeGroups"
                      :isParameters="true"
                      :isMultiple="true"
                      :isGatewayRequest="true"
                      :selectedData="selectedFilterFromGivenValues"
                      :requestUrl="
                        String.format(
                          '/Lcdp-GetOrganizationalUnitItemsForField?name={0}&filterType={1}&isAddCurrentKeys=false',
                          this.tabData.organizationalUnitFilterType,
                          this.tabData.organizationalUnitFilterType
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="row mt-3" v-if="fieldPage.selectedFieldTypeFormulaOrgUnit">
        <div class="col-md-12">
          <label for="Formula" class="form-label"
            >{{
              $t(
                "Fields.Formula",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<a
              v-if="!fieldPage.currentFieldIsSystemFormula"
              @click="fieldPage.openFormulaEditor"
              class="cursor-pointer text-decoration-none text-primary ms-1"
            >
              <span
                ><img
                  width="20"
                  height="20"
                  :src="
                    require('@/assets/images/icons/function-primary.png')
                  " /></span
              >&nbsp;
              {{
                $t(
                  "Components.FormulaEditorModal.OpenFormulaEditor",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</a
            ></label
          >
          <textarea
            name="Formula"
            class="form-control"
            id="Formula"
            :disabled="
              fieldPage.currentFieldIsSystemFormula &&
              fieldPage.fieldData.formulaName != 'SISTEM_NUMARASI'
            "
            v-model="tabData.defaultValue"
            :spellcheck="this.$isTextSpellCheck"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div class="row mt-3" :class="{}">
        <div
          class="col-md-6"
          v-if="
            !organizationalUnitDefaultValueHide &&
            fieldPage.selectedFieldTypeOrgUnit
          "
        >
          <label for="DefaultValue" class="form-label">{{
            $t(
              "Fields.DefaultValue",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <FormSelect
            v-if="this.tabData.isMultiple"
            type="SelectWithRemoteDataAndInternalSearch"
            @onMultipleSelectChange="onChangeMultipleSelectForDefaultValue"
            :selectedData="selectedDefaultValue"
            :isParameters="true"
            :isMultiple="this.tabData.isMultiple"
            :isGatewayRequest="true"
            :requestUrl="
              String.isNullOrWhiteSpace(
                this.tabData.organizationalUnitGroupPublicIds
              )
                ? String.format(
                    '/Lcdp-GetOrganizationalUnitItemsForField?name={0}&filterType={1}&isAddCurrentKeys=true',
                    this.tabData.activeTabType,
                    this.tabData.organizationalUnitFilterType
                  )
                : String.format(
                    '/Lcdp-GetOrganizationalUnitItemsForField?name={0}&filterType={1}&groupIds={2}&depth={3}&includeItSelf={4}&isAddCurrentKeys=true',
                    this.tabData.activeTabType,
                    this.tabData.organizationalUnitFilterType,
                    this.tabData.organizationalUnitGroupPublicIds,
                    this.tabData.organizationalUnitDepth,
                    this.tabData.organizationalUnitIncludeItself
                  )
            "
            :resetSelect="defaultValueReset"
          />
          <FormSelect
            v-else
            type="SelectWithRemoteDataAndInternalSearch"
            @onChange="onChangeDefaultValue"
            :selectedData="selectedDefaultValue"
            :resetSelect="defaultValueReset"
            :isParameters="true"
            :isMultiple="this.tabData.isMultiple"
            :isGatewayRequest="true"
            :requestUrl="
              String.isNullOrWhiteSpace(
                this.tabData.organizationalUnitGroupPublicIds
              )
                ? String.format(
                    '/Lcdp-GetOrganizationalUnitItemsForField?name={0}&filterType={1}&isAddCurrentKeys=true',
                    this.tabData.activeTabType,
                    this.tabData.organizationalUnitFilterType
                  )
                : String.format(
                    '/Lcdp-GetOrganizationalUnitItemsForField?name={0}&filterType={1}&groupIds={2}&depth={3}&includeItSelf={4}&isAddCurrentKeys=true',
                    this.tabData.activeTabType,
                    this.tabData.organizationalUnitFilterType,
                    this.tabData.organizationalUnitGroupPublicIds,
                    this.tabData.organizationalUnitDepth,
                    this.tabData.organizationalUnitIncludeItself
                  )
            "
          />
        </div>
        <div class="col-md-6" v-if="orginizationalUnitShowColumnsVisibility">
          <label
            for="OrganizationalUnitShowColumns"
            class="form-label required"
            >{{
              $t(
                "Fields.OrganizationalUnitShowColumns",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label
          >
          <FormSelect
            type="SelectWithLocalData"
            @onMultipleSelectChange="onChangeShowColumns"
            :allowEmpty="false"
            :isMultiple="true"
            :data="organizationalUnitShowColumnsData"
            :selectedData="selectedShowColumnValues"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6" v-if="organizationalUnitIncludeItselfShow">
          <div class="form-check">
            <label for="OrganizationalUnitIncludeItself" class="form-label">{{
              $t(
                "Fields.IncludeItself",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <input
              class="form-check-input"
              type="checkbox"
              id="OrganizationalUnitIncludeItself"
              v-model="tabData.organizationalUnitIncludeItself"
            />
          </div>
        </div>
        <div class="col-md-6" v-if="fieldPage.selectedFieldTypeOrgUnit">
          <template v-if="isRecordEdit">
            <div class="form-check">
              <label
                for="IsMultiple"
                class="form-label"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="
                  this.$t(
                    'BaseModelFields.NoUpdatedFeature',
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                "
                >{{
                  $t(
                    "Fields.MultipleSelect",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
              <input
                class="form-check-input"
                disabled
                type="checkbox"
                id="IsMultiple"
                :checked="tabData.isMultiple"
              />
            </div>
          </template>
          <div class="form-check" v-else>
            <label for="IsMultiple" class="form-label">{{
              $t(
                "Fields.MultipleSelect",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <input
              class="form-check-input"
              type="checkbox"
              id="IsMultiple"
              v-model="tabData.isMultiple"
              @change="onChangeMultipleValue"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrganizationalUnitTab",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    tabsData: {
      type: Array,
      default: () => [
        {
          value: "User",
          prefix: "user_",
          iconClass: "bi bi-person-fill",
          radioButtons: [
            {
              id: "allUser",
              name: "AllUsers",
              param: "AllUsers",
              depth: 1,
              filterType: "User",
              active: true,
              includeItselfShow: false,
            },
            {
              id: "useroftheUserGroup",
              name: "UseroftheUserGroup",
              param: "UseroftheUserGroup",
              depth: 2,
              filterType: "UserGroup",
              includeItselfShow: true,
              childRadioButtons: [
                {
                  id: "userOrgChildGroups",
                  name: "ChildrenGroups",
                  param: "ChildGroups",
                  depth: 2,
                  childItemRadioButtons: [
                    {
                      id: "childGroupFilterField",
                      name: "FilterFromField",
                      param: "FilterFromField",
                    },
                    {
                      id: "childGroupFilterData",
                      name: "FilterFromGiven",
                      param: "FilterFromGiven",
                    },
                  ],
                },
                // {
                //   id: "userOrgAllChildGroups",
                //   name: "AllDescendantsGroups",
                //   param: "AllChildGroups",
                //   depth: 3,
                //   childItemRadioButtons: [
                //     {
                //       id: "childGroupFilterField",
                //       name: "FilterFromField",
                //       param: "FilterFromField",
                //     },
                //     {
                //       id: "childGroupFilterData",
                //       name: "FilterFromGiven",
                //       param: "FilterFromGiven",
                //     },
                //   ],
                // },
              ],
            },
            {
              id: "usersofthePermissionGroup",
              name: "UsersofthePermissionGroup",
              param: "UsersofthePermissionGroup",
              depth: 3,
              filterType: "PermissionGroup",
              includeItselfShow: true,
              childRadioButtons: [
                {
                  id: "userOrgChildGroups",
                  name: "ChildrenGroups",
                  param: "UserOrgAllChildFilterGroups",
                  depth: 2,
                  childItemRadioButtons: [
                    {
                      id: "childGroupFilterField",
                      name: "FilterFromField",
                      param: "FilterFromField",
                    },
                    {
                      id: "childGroupFilterData",
                      name: "FilterFromGiven",
                      param: "FilterFromGiven",
                    },
                  ],
                },
                // {
                //   id: "userOrgAllChildGroups",
                //   name: "AllDescendantsGroups",
                //   param: "UserOrgAllDescendantsGroups",
                //   depth: 3,
                //   childItemRadioButtons: [
                //     {
                //       id: "childGroupFilterField",
                //       name: "FilterFromField",
                //       param: "FilterFromField",
                //     },
                //     {
                //       id: "childGroupFilterData",
                //       name: "FilterFromGiven",
                //       param: "FilterFromGiven",
                //     },
                //   ],
                // },
              ],
            },
          ],
        },
        {
          value: "UserGroup",
          prefix: "userGroup_",
          iconClass: "bi bi-people-fill",
          radioButtons: [
            {
              id: "allUserGroup",
              name: "AllUserGroup",
              param: "UserGroupDepth1",
              depth: 1,
              filterType: "UserGroup",
              active: true,
              includeItselfShow: false,
            },
            {
              id: "childenUserGroupsTab2",
              name: "ChildrenGroups",
              param: "UserGroupDepth2",
              depth: 2,
              filterType: "UserGroup",
              includeItselfShow: true,
              childRadioButtons: [
                {
                  id: "childGroupFilterFieldTab2",
                  name: "FilterFromField",
                  param: "FilterFromField",
                },
                {
                  id: "childGroupFilterDataTab2",
                  name: "FilterFromGiven",
                  param: "FilterFromGiven",
                },
              ],
            },
            // {
            //   id: "allDescendantsGroups",
            //   name: "AllDescendantsGroups",
            //   param: "UserGroupDepth3",
            //   depth: 3,
            //   filterType: "UserGroup",
            //   includeItselfShow: true,
            //   childRadioButtons: [
            //     {
            //       id: "childGroupFilterFieldTab2",
            //       name: "FilterFromField",
            //       param: "FilterFromField",
            //     },
            //     {
            //       id: "childGroupFilterDataTab2",
            //       name: "FilterFromGiven",
            //       param: "FilterFromGiven",
            //     },
            //   ],
            // },
          ],
        },
        {
          value: "PermissionGroup",
          prefix: "permissionGroup_",
          iconClass: "bi bi-lock-fill",
          radioButtons: [
            {
              id: "allPermissionGroup",
              name: "AllUserGroup", //!!
              param: "PermissionGroupDepth1",
              depth: 1,
              filterType: "PermissionGroup",
              active: true,
              includeItselfShow: false,
            },
            {
              id: "childenPermissinGroups",
              name: "ChildrenGroups",
              param: "PermissionGroupDepth2",
              depth: 2,
              filterType: "PermissionGroup",
              includeItselfShow: true,
              childRadioButtons: [
                {
                  id: "childGroupFilterFieldTab3",
                  name: "FilterFromField",
                  param: "FilterFromField",
                },
                {
                  id: "childGroupFilterDataTab3",
                  name: "FilterFromGiven",
                  param: "FilterFromGiven",
                },
              ],
            },
            // {
            //   id: "allDescendantPermissionGroups",
            //   name: "AllDescendantsGroups",
            //   param: "PermissionGroupDepth3",
            //   depth: 3,
            //   filterType: "PermissionGroup",
            //   includeItselfShow: true,
            //   childRadioButtons: [
            //     {
            //       id: "childGroupFilterFieldTab3",
            //       name: "FilterFromField",
            //       param: "FilterFromField",
            //     },
            //     {
            //       id: "childGroupFilterDataTab3",
            //       name: "FilterFromGiven",
            //       param: "FilterFromGiven",
            //     },
            //   ],
            // },
          ],
        },
      ],
    },
  },
  data() {
    return {
      fieldPage: this.$parent,
      tabData: null,
      tabList: [],
      isRecordEdit: false,
      defaultTabData: {
        activeTabType: "User",
        organizationalUnitFilterType: "User",
        organizationalUnitName: "User",
        isMultiple: false,
        organizationalUnitIncludeItself: false,
        organizationalUnitGroupPublicIds: null,
        organizationalUnitShowColumns: null,
        organizationalUnitDepth: 1,
        defaultValue: null,
      },

      parentRadioType: "",
      childRadioType: "",
      childItemRadioType: "",

      defaultValueReset: false,
      organizationalUnitIncludeItselfShow: false,
      organizationalUnitDefaultValueHide: false,
      orginizationalUnitShowColumnsVisibility: true,
      organizationalUnitShowColumnsData: [
        {
          key: "FirstName",
          value: this.$t(
            "Fields.OrgUnitShowColumForName",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "LastName",
          value: this.$t(
            "Fields.OrgUnitShowColumnForSurname",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "Email",
          value: this.$t(
            "Fields.OrgUnitShowColumnForEmail",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],

      selectedDefaultValue: [],
      selectedFilterFromFieldValues: [],
      selectedFilterFromGivenValues: [],
      selectedShowColumnValues: [],
    };
  },
  created() {
    this.isRecordEdit =
      this.data && !String.isNullOrWhiteSpace(this.data.publicId);
    if (this.isRecordEdit) {
      this.tabData = {
        activeTabType: this.data.organizationalUnitName,
        organizationalUnitFilterType: this.data.organizationalUnitFilterType,
        organizationalUnitName: this.data.organizationalUnitName,
        isMultiple: this.data.isMultipleValue,
        organizationalUnitIncludeItself:
          this.data.organizationalUnitIncludeItself,
        organizationalUnitGroupPublicIds:
          this.data.organizationalUnitGroupPublicIds,
        organizationalUnitShowColumns: this.data.organizationalUnitShowColumns,
        organizationalUnitDepth: this.data.organizationalUnitDepth,
        defaultValue: this.data.defaultValue,
      };

      this.tabList = this.tabsData.filter(
        (f) => f.value == this.data.organizationalUnitName
      );

      this.orginizationalUnitShowColumnsVisibility =
        this.data.organizationalUnitName == "User";

      if (!String.isNullOrWhiteSpace(this.data.defaultValue)) {
        if (String.isNullOrWhiteSpace(this.data.defaultValuePublicIds)) {
          var defaulValueList = this.data.defaultValue.split(
            this.$systemSeparator
          );
          defaulValueList.forEach((v, i) => {
            this.selectedDefaultValue.push({
              key: v,
              value: v,
            });
          });
        } else {
          var defaulValueSplitList = this.data.defaultValue.split(
            this.$systemSeparator
          );
          var defaulValuePublicIdList = this.data.defaultValuePublicIds.split(
            this.$systemSeparator
          );
          defaulValuePublicIdList.forEach((v, i) => {
            this.selectedDefaultValue.push({
              key: v,
              value: defaulValueSplitList[i],
            });
          });
        }
      }

      if (!String.isNullOrWhiteSpace(this.data.organizationalUnitShowColumns)) {
        var selectedOrganizationUnitShowColumnList =
          this.data.organizationalUnitShowColumns.split(this.$systemSeparator);
        selectedOrganizationUnitShowColumnList.forEach((v) => {
          var item = this.organizationalUnitShowColumnsData.find(
            (f) => f.key === v
          );
          if (!String.isNullOrWhiteSpace(item)) {
            this.selectedShowColumnValues.push(item);
          } else {
            this.selectedShowColumnValues.push({
              key: v,
              value: v,
            });
          }
        });
      }

      if (
        !String.isNullOrWhiteSpace(this.data.controllingPublicId) &&
        !String.isNullOrWhiteSpace(this.data.controllingName)
      ) {
        this.childItemRadioType = "FilterFromField";
        this.organizationalUnitDefaultValueHide = true;
        this.selectedFilterFromFieldValues.push({
          key: this.data.controllingPublicId,
          value: this.data.controllingName,
        });
      } else if (
        !String.isNullOrWhiteSpace(
          this.data.organizationalUnitGroupPublicIds
        ) &&
        !String.isNullOrWhiteSpace(this.data.organizationalUnitGroupNames)
      ) {
        this.childItemRadioType = "FilterFromGiven";
        this.organizationalUnitDefaultValueHide = false;

        var selectedOrganizationUnitGroupList =
          this.data.organizationalUnitGroupPublicIds.split(
            this.$systemSeparator
          );
        var selectedOrganizationUnitGroupNameList =
          this.data.organizationalUnitGroupNames.split(this.$systemSeparator);
        selectedOrganizationUnitGroupList.forEach((v, i) => {
          this.selectedFilterFromGivenValues.push({
            key: v,
            value: selectedOrganizationUnitGroupNameList[i],
          });
        });
      }

      if (
        this.data.organizationalUnitFilterType === "UserGroup" ||
        this.data.organizationalUnitFilterType === "PermissionGroup"
      ) {
        this.organizationalUnitIncludeItselfShow = true;
      }

      if (
        !String.isNullOrWhiteSpace(this.data.organizationalUnitName) &&
        !String.isNullOrWhiteSpace(this.data.organizationalUnitFilterType)
      ) {
        var tab = this.tabList[0],
          radioItem = null,
          childRadioItem = null;

        if (tab.value == "User") {
          radioItem = tab.radioButtons.find(
            (f) => f.filterType == this.data.organizationalUnitFilterType
          );

          if (radioItem.childRadioButtons) {
            childRadioItem = radioItem.childRadioButtons.find(
              (f) => f.depth == this.data.organizationalUnitDepth
            );
          } else {
            childRadioItem = {
              param: radioItem.param,
            };
          }
        } else {
          radioItem = tab.radioButtons.find(
            (f) =>
              f.filterType == this.data.organizationalUnitFilterType &&
              f.depth == this.data.organizationalUnitDepth
          );
        }

        if (!radioItem) {
          console.error("Radio item not found.");
          return;
        }

        this.parentRadioType = radioItem.param;

        if (tab.value == "User") {
          if (!childRadioItem) {
            console.error("User child radio button item not found.");
            return;
          } else {
            this.childRadioType = childRadioItem.param;
          }
        }
      }
    } else {
      this.tabList = this.tabsData;
      this.tabData = this.defaultTabData;
    }
  },
  methods: {
    checked(radioIndex, radioItem) {
      var returnValue = false;
      if (this.tabData.activeTabType == "User") {
        if (
          this.tabData.organizationalUnitFilterType === radioItem.filterType
        ) {
          returnValue = true;
        } else {
          returnValue = false;
        }
      } else {
        if (radioIndex == 0) {
          if (
            this.tabData.organizationalUnitDepth == 1 &&
            this.tabData.organizationalUnitFilterType === radioItem.filterType
          ) {
            returnValue = true;
          } else {
            returnValue = false;
          }
        } else {
          if (this.tabData.organizationalUnitDepth == radioIndex + 1) {
            returnValue = true;
          } else {
            returnValue = false;
          }
        }
      }

      return returnValue;
    },
    tabClick(item) {
      if (this.isRecordEdit) return;

      this.childItemRadioType = "";
      this.childRadioType = "";
      this.parentRadioType = "";

      this.organizationalUnitIncludeItselfShow = false;
      this.tabData.organizationalUnitIncludeItself = false;
      this.tabData.organizationalUnitName = item.value;
      this.tabData.activeTabType = item.value;

      this.tabData.organizationalUnitFilterType = item.value;

      this.tabData.organizationalUnitDepth = 1;
      this.tabData.controllingPublicId = null;
      this.tabData.organizationalUnitGroupPublicIds = null;

      this.tabData.defaultValue = null;
      this.defaultValueReset = !this.defaultValueReset;
      this.organizationalUnitDefaultValueHide = false;

      this.orginizationalUnitShowColumnsVisibility = item.value == "User";
    },
    filter(radioItem, item) {
      this.parentRadioType = radioItem.param;
      this.childItemRadioType = "";
      this.childRadioType = radioItem.name;

      this.tabData.activeTabType = item.value;
      this.tabData.organizationalUnitFilterType = radioItem.filterType;
      this.organizationalUnitIncludeItselfShow = radioItem.includeItselfShow;

      this.defaultValueReset = !this.defaultValueReset;
      this.selectedFilterFromFieldValues = [];
      this.selectedFilterFromGivenValues = [];
      this.tabData.controllingPublicId = null;
      this.tabData.organizationalUnitGroupPublicIds = null;

      this.organizationalUnitDefaultValueHide = false;

      if (!String.isNullOrWhiteSpace(radioItem.depth)) {
        this.tabData.organizationalUnitDepth = radioItem.depth;
      } else {
        this.tabData.organizationalUnitDepth = 1;
      }
    },
    onChildFilter(type, depth) {
      this.childItemRadioType = "";
      this.childRadioType = type;
      this.defaultValueReset = !this.defaultValueReset;
      this.tabData.controllingPublicId = null;
      this.selectedFilterFromFieldValues = [];
      this.selectedFilterFromGivenValues = [];

      this.tabData.organizationalUnitGroupPublicIds = null;
      if (!String.isNullOrWhiteSpace(depth)) {
        this.tabData.organizationalUnitDepth = depth;
      } else {
        this.tabData.organizationalUnitDepth = 1;
      }
    },
    onChildFilterFrom(type) {
      this.childItemRadioType = type;
      this.defaultValueReset = !this.defaultValueReset;
      this.tabData.controllingPublicId = null;
      this.tabData.organizationalUnitGroupPublicIds = null;
      this.selectedFilterFromFieldValues = [];
      this.selectedFilterFromGivenValues = [];

      if (type == "FilterFromGiven") {
        this.organizationalUnitDefaultValueHide = false;
      } else this.organizationalUnitDefaultValueHide = true;
    },
    onChangeControlling(selected) {
      this.tabData.controllingPublicId = selected.key;
    },
    onChangeMultipleSelectForDefaultValue(ids) {
      this.tabData.defaultValue = ids;
    },
    onChangeDefaultValue(selected) {
      this.tabData.defaultValue = selected.key;
    },
    onChangeMultipleValue() {
      this.tabData.defaultValue = null;
      this.defaultValueReset = !this.defaultValueReset;
    },
    onChangeGroups(ids) {
      this.tabData.organizationalUnitGroupPublicIds = ids;
    },
    onChangeShowColumns(ids) {
      this.tabData.organizationalUnitShowColumns = ids;
    },
  },
};
</script>
