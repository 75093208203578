<template>
  <button
    id="message-modal"
    style="display: none"
    class="btn btn-success hidden"
    data-bs-toggle="modal"
    data-bs-target="#modalMessage"
  >
    Open
  </button>
  <div
    class="modal fade"
    id="modalMessage"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="modalMessageLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="alert alert-danger mb-0">
            {{
              $t(this.messageKey, {}, { locale: this.$store.state.activeLang })
            }}
          </div>
        </div>
        <div class="modal-footer p-1">
          <button
            type="button"
            class="btn btn-success btn-close-modal"
            @click="yes"
            data-bs-dismiss="modal"
          >
            {{
              $t(
                "BaseModelFields.Yes",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-close-modal"
            @click="no"
            data-bs-dismiss="modal"
          >
            {{
              $t(
                "BaseModelFields.No",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AlertModal",
  props: ["messageKey"],
  data() {
    return {};
  },
  emits: ["yes", "no"],
  methods: {
    yes() {
      this.$emit("yes");
    },
    no() {
      this.$emit("no");
    },
  },
};
</script>
